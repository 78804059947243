import React from "react";
import moment from "moment";
import { NoteRecord } from "../interfaces/noteRecord";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { useFirebase } from "../hooks/useFirebase";

export const NotesCard = ({ note }: { note: NoteRecord }) => {
  const fb = useFirebase();
  const deleteMe = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Delete?`)) {
        fb.note(note.id).delete();
      }
    },
    [fb, note.id]
  );

  return (
    <>
      <div className="flex flex-col w-64 h-96 max-w-5/6 mt-2 relative">
        <a
          href="/"
          onClick={deleteMe}
          className="absolute bg-burgundy border-khaki border text-white shadow-sm z-10 rounded-full right-0 top-0 p-1"
          style={{ margin: "-0.3em" }}
        >
          <MdClose />
        </a>
        <Link to={`/note/${note.id}`} className="flex-grow flex h-96">
          <div className="flex-grow p-2 border rounded-xl shadow-lg bg-white text-ebony whitespace-pre-line overflow-hidden">
            {note.content}
          </div>
        </Link>
      </div>
      <div>{moment(note.modifiedOn).fromNow()}</div>
    </>
  );
};
