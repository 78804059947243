import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Header } from "../components/header";
import { useUser } from "../contexts/userContext";
import { MdContentCopy, MdContentPaste } from "react-icons/md";
import { Footer } from "../components/footer";
import { useNote } from "../hooks/useNote";
import moment from "moment";

export function Note({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) {
  const user = useUser();
  const { note, update } = useNote(id);
  const [message, setMessage] = React.useState<string>();

  const change = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      update(e.target.value);
    },
    [update]
  );

  const onCopy = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      navigator.clipboard
        .writeText(note.content || "")
        .then(() => {
          setMessage("Text copied!");
          setTimeout(setMessage, 3000);
        })
        .catch((ex) => console.error(ex));
    },
    [note.content]
  );

  const onPaste = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    const el = document.getElementById("noteText") as HTMLTextAreaElement;
    if (!el) return;
    navigator.clipboard
      .readText()
      .then((text) => {
        const [start, end] = [el.selectionStart, el.selectionEnd];
        el.setRangeText(text, start, end, "select");
      })
      .catch((ex) => console.error(ex));
  }, []);

  if (!user) return null;

  return (
    <div className="flex flex-col flex-grow bg-ebony text-khaki">
      <Header>
        <button type="button" className="button" onClick={onCopy}>
          <MdContentCopy /> Copy
        </button>
        <button type="button" className="button" onClick={onPaste}>
          <MdContentPaste /> Paste
        </button>
      </Header>
      {message && <div className="bg-yellow-200 text-gray-800">{message}</div>}
      <div className="flex flex-col flex-grow">
        <textarea
          id="noteText"
          className="flex-grow text-black text-lg p-4"
          value={note.content}
          onChange={change}
        />
      </div>
      <Footer text={`Modified: ${moment(note.modifiedOn).format("lll")}`} />
    </div>
  );
}
