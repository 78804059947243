import React from "react";
import { NoteRecord } from "../interfaces/noteRecord";
import { useFirebase } from "./useFirebase";

export const useNotesList = () => {
  const fb = useFirebase();
  const [notes, setNotes] = React.useState<NoteRecord[]>([]);

  React.useEffect(() => {
    return fb
      .notes()
      .orderBy(`modifiedOn`, "desc")
      .onSnapshot((notes) =>
        setNotes(notes.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
  }, [fb]);

  return notes;
};
