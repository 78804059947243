import React from "react";
import SwiperCore, { Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useNotesList } from "../hooks/useNotesList";
import { NoteAddNew } from "./noteAddNew";
import { NotesCard } from "./notesCard";

// install Swiper modules: Navigation,
SwiperCore.use([Pagination, Scrollbar, A11y]);

export const NotesList = () => {
  const notes = useNotesList();

  return (
    <div className="w-full">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        breakpoints={{
          600: { slidesPerView: 2 },
          900: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
          1500: { slidesPerView: 5 },
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {notes.map((note) => (
          <SwiperSlide key={note.id}>
            <div className="flex flex-col justify-center items-center mb-12">
              <NotesCard note={note} />
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <div className="flex flex-col justify-center items-center mb-12">
            <NoteAddNew />
          </div>
        </SwiperSlide>
        <div className="swiper-pagination text-white" />
        {/* <div className="swiper-button-prev swiper-button-white" />
        <div className="swiper-button-next swiper-button-white" /> */}
      </Swiper>
    </div>
  );
};
