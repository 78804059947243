import React, { useCallback } from "react";
import { MdExitToApp, MdRefresh } from "react-icons/md";
import { useUser } from "../contexts/userContext";
import { useFirebase } from "../hooks/useFirebase";
import { PropChildren } from "../interfaces/PropChildren";
import { UserIcon } from "./userIcon";

export const UserMenu = ({ children }: PropChildren) => {
  const fb = useFirebase();
  const user = useUser();
  const [show, setShow] = React.useState(false);

  const showMenu = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    setShow((b) => !b);
  }, []);
  const signOut = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      fb.doSignOut();
    },
    [fb]
  );

  const refresh = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    window.location.reload();
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div className="relative">
      <a href="/" onClick={showMenu}>
        {children}
      </a>
      <div className={`z-40 absolute right-0 top-full ${show ? "" : "hidden"}`}>
        <div className="menu bg-white text-black min-w-16">
          <div className="flex flex-col items-center border text-lg whitespace-nowrap px-4 py-2 border-b bg-gray-100">
            <UserIcon className="w-16 h-16" />
            {user.displayName || user.email}
          </div>
          <a href="/" onClick={refresh}>
            <MdRefresh /> Reload
          </a>
          <a href="/" onClick={signOut}>
            <MdExitToApp /> Sign Out
          </a>
        </div>
      </div>
    </div>
  );
};
