import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { NoteRecord } from "../interfaces/noteRecord";
import { UserRecord } from "../interfaces/userRecord";

const firebaseConfig = {
  apiKey: "AIzaSyDqECksOEar7RlcIN6SK8Z9CgHmfq0LVpg",
  authDomain: "rok-texted-app.firebaseapp.com",
  databaseURL: "https://rok-texted-app-default-rtdb.firebaseio.com",
  projectId: "rok-texted-app",
  messagingSenderId: "159831363507",
  appId: "1:159831363507:web:6fc06a3ad3cbc39861c5c6",
  measurementId: "G-X48NCR6QR2",
};

export class Firebase {
  user: app.User | null;
  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  googleProvider: app.auth.GoogleAuthProvider;
  setReady?: () => void;

  constructor() {
    this.user = null;
    app.initializeApp(firebaseConfig);
    (app as any).initializeApp = null;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();

    this.onAuthUserListener((u) => {
      this.setReady?.();
      this.user = u?.uid ? u : null;
      this.updateUserProfile(u);
    });
  }

  // *** Auth API ***
  doSignInWithGoogle = (): Promise<void> => {
    return this.auth
      .signInWithPopup(this.googleProvider)
      .then(async (u) => {
        if (u.user?.uid) {
          const ref = this.db
            .collection("users")
            .doc(u.user?.uid) as app.firestore.DocumentReference<UserRecord>;
          const doc = await ref.get();
          if (!doc.exists || !doc.get("createdOn")) {
            const now = new Date();
            await ref.set(
              {
                uid: u.user.uid,
                email: u.user.email,
                displayName: u.user.displayName,
                avatarIcon: u.user.photoURL,
                createdOn: now.toISOString(),
                lastSeenOn: now.toISOString(),
              },
              { merge: true }
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error.message);
      });
  };

  doSignOut = () => this.auth.signOut();

  updateUserProfile = (authUser: app.User | null) => {
    if (authUser?.uid) {
      (this.db
        .collection("users")
        .doc(authUser?.uid) as app.firestore.DocumentReference<UserRecord>)
        .set(
          {
            uid: authUser.uid,
            email: authUser.email,
            displayName: authUser.displayName,
            avatarIcon: authUser.photoURL,
            lastSeenOn: new Date().toISOString(),
          },
          { merge: true }
        )
        .catch((ex) => {
          console.warn(ex);
        });
    }
  };

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next: (u: app.User | null) => void) =>
    this.auth.onAuthStateChanged((authUser) => next(authUser || null));

  // *** Data API ***

  userId = () => {
    if (!this.auth.currentUser?.uid) {
      throw new Error(`User is not logged in.`);
    }
    return this.auth.currentUser?.uid;
  };
  self = () =>
    this.db
      .collection("users")
      .doc(this.userId()) as app.firestore.DocumentReference<UserRecord>;
  notes = () =>
    this.self().collection(
      "notes"
    ) as app.firestore.CollectionReference<NoteRecord>;
  note = (uid: string) => this.notes().doc(uid);
}
