import React from "react";
import { MdAdd } from "react-icons/md";
import { useNewNote } from "../hooks/useNewNote";

export const NoteAddNew = () => {
  const addNew = useNewNote();
  return (
    <>
      <a
        href="/"
        onClick={addNew}
        className="flex flex-col w-64 h-96 max-w-5/6 mt-2 relative"
      >
        <div className="flex flex-grow flex-col h-96 justify-center items-center p-4 border rounded-xl shadow-lg whitespace-pre-line">
          <MdAdd className="text-8xl" />
        </div>
      </a>
      <div>Add New</div>
    </>
  );
};
