import { useEffect, useState } from "react";
import { UserRecord } from "../interfaces/userRecord";
import { useFirebase } from "./useFirebase";
import { useFirebaseUser } from "./useFirebaseUser";

export const useSelf = (): UserRecord | null => {
  const fb = useFirebase();
  const user = useFirebaseUser();

  const [self, setSelf] = useState<UserRecord>();

  useEffect(() => {
    if (!user?.uid) {
      setSelf(undefined);
    } else {
      return fb.self().onSnapshot((doc) => setSelf(doc.data()));
    }
  }, [fb, user?.uid]);

  return self || null;
};
