import React, { useEffect, useState } from "react";
import { FirebaseContext } from "./contexts/firebaseContext";
import { Routes } from "./pages/routes";
import { Firebase } from "./services/firebase";

const firebase = new Firebase();

export function App() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    firebase.setReady = () => setReady(true);
  }, []);

  if (!ready) {
    return <div />;
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      <Routes />
    </FirebaseContext.Provider>
  );
}
