import React from "react";
import { useHistory } from "react-router";
import { useFirebase } from "./useFirebase";

export const useNewNote = () => {
  const fb = useFirebase();
  const history = useHistory();

  const addNew = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      const id = fb.notes().doc().id;
      const now = new Date().toISOString();
      fb.note(id)
        .set({
          id,
          content: "",
          createdOn: now,
          modifiedOn: now,
          revision: 1,
        })
        .then(() => {
          history.push(`/note/${id}`);
        });
    },
    [fb, history]
  );

  return addNew;
};
