import React from "react";
import { Header } from "../components/header";
import { useUser } from "../contexts/userContext";
import { MdNoteAdd } from "react-icons/md";
import { NotesList } from "../components/notesList";
import { Footer } from "../components/footer";
import { useNewNote } from "../hooks/useNewNote";

export function Home() {
  const user = useUser();
  const addNew = useNewNote();

  if (!user) return null;

  return (
    <div className="flex flex-col flex-grow bg-ebony text-khaki">
      <Header home>
        <button type="button" className="button" onClick={addNew}>
          <MdNoteAdd /> Add New
        </button>
      </Header>
      <div className="flex-grow p-6 pt-10 pb-0 flex flex-col justify-center">
        <NotesList />
      </div>
      <Footer />
    </div>
  );
}
