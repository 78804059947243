import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import { useFirebaseUser } from "../hooks/useFirebaseUser";
import { useSelf } from "../hooks/useSelf";
import { Home } from "./home";
import { Note } from "./note";
import { SignIn } from "./sign-in";

const AppRoutes = () => {
  const user = useFirebaseUser();
  const self = useSelf();

  if (!user) {
    return (
      <Switch>
        <Route exact={false} path="/" component={SignIn} />
      </Switch>
    );
  }

  return (
    <UserContext.Provider value={self}>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={false} path="/note/:id" component={Note} />
      </Switch>
    </UserContext.Provider>
  );
};

export const Routes = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};
