import moment from "moment";
import React from "react";
import { useUser } from "../contexts/userContext";
export const Footer = ({ text }: { text?: string }) => {
  const user = useUser();

  return (
    <div className="flex p-2 bg-burgundy border-khaki border-t whitespace-nowrap">
      <div className="flex flex-grow items-center sm:justify-start justify-center">
        &copy; 2021, all rights reserved.
      </div>
      <div className="hidden sm:flex flex-grow items-center justify-end">
        {text ||
          (user?.createdOn
            ? `Joined: ${moment(user?.createdOn).format("lll")}`
            : "")}
      </div>
    </div>
  );
};
