import React from "react";
import { MdHome } from "react-icons/md";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/userContext";
import { PropChildren } from "../interfaces/PropChildren";
import { UserIcon } from "./userIcon";
import { UserMenu } from "./userMenu";

export const Header = ({
  children,
  home,
}: { home?: boolean } & PropChildren) => {
  const user = useUser();
  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-row h-16 bg-gray-300 shadow-lg border-gray-100 border-b-2 border-t-2">
      <div className="flex-grow flex items-center p-2 px-4 mr-child-2">
        {children}
      </div>
      <div className="flex items-center p-2 px-4">
        {home ? (
          <UserMenu>
            <UserIcon />
          </UserMenu>
        ) : (
          <Link
            to="/"
            className="button bg-burgundy text-coolGray-50"
            style={{ padding: "0.25rem" }}
          >
            <MdHome className="w-8 h-8" style={{ margin: "0" }} />
          </Link>
        )}
      </div>
    </div>
  );
};
