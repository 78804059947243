import React, { useCallback, useMemo } from "react";
import { NoteRecord } from "../interfaces/noteRecord";
import { useFirebase } from "./useFirebase";

export const useNote = (id: string) => {
  const fb = useFirebase();
  const ref = useMemo(() => fb.note(id), [fb, id]);

  const [note, setNote] = React.useState<
    Pick<NoteRecord, "id"> & Partial<NoteRecord> & { isModified: boolean }
  >({ id, isModified: false });

  React.useEffect(() => {
    return ref.onSnapshot((doc) => {
      const newNote = { ...doc.data(), id: doc.id, isModified: false };
      setNote((current) => {
        if (
          !current.isModified ||
          (newNote.revision && newNote.revision > (current.revision || 0))
        ) {
          return newNote;
        }
        return current;
      });
    });
  }, [ref]);

  React.useEffect(() => {
    if (note.isModified) {
      ref.update({
        revision: note.revision || 0,
        content: note.content || "",
        modifiedOn: new Date().toISOString(),
      });
    }
  }, [ref, note]);

  const update = useCallback((content: string) => {
    setNote((current) => ({
      ...current,
      content,
      revision: (current.revision || 0) + 1,
      isModified: true,
    }));
  }, []);

  return { note, update };
};
